import { UntypedFormBuilder, UntypedFormGroup, FormGroup } from '@angular/forms';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PatientProfileFormComponent } from '../patient-profile-form/patient-profile-form.component';
import { PharmacyInfoFormComponent } from '../pharmacy-info-form/pharmacy-info-form.component';

import { SuccessResponse } from '@insig-health/api/patient-management-api';

import { PatientProfile, PatientProfileService } from '@insig-health/services/patient-profile/patient-profile.service';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/angular-material.config';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'insig-booking-edit-patient-form-dialog',
  templateUrl: './edit-patient-form-dialog.component.html',
})
export class EditPatientFormDialogComponent {

  public static readonly DIALOG_MAX_WIDTH = '796px';
  public static readonly DIALOG_WIDTH = '100vw';
  public static readonly DEFAULT_ERROR_SNACKBAR_STRING = 'An error occurred while updating the patient';

  public PATIENT_PROFILE_FORM_NAME = PatientProfileFormComponent.PATIENT_PROFILE_FORM_NAME;
  public PHARMACY_FORM_NAME = PharmacyInfoFormComponent.PHARMACY_FORM_NAME;

  @ViewChild('patientProfileForm') patientProfileForm!: PatientProfileFormComponent;
  @ViewChild('pharmacyInfoForm') pharmacyInfoForm!: PharmacyInfoFormComponent;

  public patientProfile = this.data.patientProfile;

  public readonly editPatientForm = this.formBuilder.group({});

  constructor(
    private dialogRef: MatDialogRef<EditPatientFormDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private patientProfileService: PatientProfileService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: { patientProfile: PatientProfile },
  ) { }

  handleBackButtonClicked(): void {
    this.dialogRef.close(false);
  }

  async handleSaveButtonClicked(form: UntypedFormGroup): Promise<void> {
    if (form.invalid) {
      return;
    }

    try {
      await this.saveAccountHolderProfileInfo(form);
      this.dialogRef.close(true);
      this.snackBar.open('Patient was updated successfully', undefined, { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS });
    } catch (error) {
      console.error(error);
      this.snackBar.open(
        (error as HttpErrorResponse).error?.errorMessage ?? EditPatientFormDialogComponent.DEFAULT_ERROR_SNACKBAR_STRING,
        undefined,
        { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS },
      );
    }
  }

  saveAccountHolderProfileInfo(form: UntypedFormGroup): Promise<SuccessResponse> {
    const patientNonMedicalInfo = this.getPatientProfileFromForm(form);
    return this.patientProfileService.setCurrentUserPatientProfile(patientNonMedicalInfo);
  }

  getPatientProfileFromForm(form: UntypedFormGroup): PatientProfile {
    return {
      uid: this.patientProfile.uid,
      firstName: (form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup).controls[this.patientProfileForm.FIRST_NAME_FORM_CONTROL_NAME].value,
      lastName: (form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup).controls[this.patientProfileForm.LAST_NAME_FORM_CONTROL_NAME].value,
      birthdate: (form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup).controls[this.patientProfileForm.DATE_OF_BIRTH_FORM_CONTROL_NAME].value,
      gender: (form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup).controls[this.patientProfileForm.GENDER_FORM_CONTROL_NAME].value,
      address: (form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup).controls[this.patientProfileForm.STREET_ADDRESS_FORM_CONTROL_NAME].value,
      province: (form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup).controls[this.patientProfileForm.PROVINCE_FORM_CONTROL_NAME].value,
      city: (form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup).controls[this.patientProfileForm.CITY_FORM_CONTROL_NAME].value,
      country: this.patientProfile.country,
      postalCode: (form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup).controls[this.patientProfileForm.POSTAL_CODE_FORM_CONTROL_NAME].value,
      phone: (form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup).controls[this.patientProfileForm.PHONE_NUMBER_FORM_CONTROL_NAME].value,
      healthCardNumber: (form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup).controls[this.patientProfileForm.HEALTH_CARD_NUMBER_FORM_CONTROL_NAME].value,
      pharmacyName: (form.controls[this.PHARMACY_FORM_NAME] as FormGroup).controls[this.pharmacyInfoForm.PHARMACY_NAME_FORM_CONTROL_NAME].value,
      pharmacyFax: (form.controls[this.PHARMACY_FORM_NAME] as FormGroup).controls[this.pharmacyInfoForm.PHARMACY_FAX_NUMBER_FORM_CONTROL_NAME].value,
      familyDoctorFullName: (form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup).controls[this.patientProfileForm.FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME].value,
      familyDoctorFaxNumber: (form.controls[this.PATIENT_PROFILE_FORM_NAME] as FormGroup).controls[this.patientProfileForm.FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME].value,
    } as PatientProfile;
  }
}
