<div class="d-flex flex-column px-md-5 h-100 backgroundImage">
  <ng-container *ngTemplateOutlet="mobileView"></ng-container>
  <ng-container *ngTemplateOutlet="desktopView"></ng-container>
</div>

<ng-template #mobileView>
  <div class="d-flex d-md-none flex-column h-100">
    <insig-ui-expansion-panel #expansionPanel class="sticky-top" [expanded]="false" [flushStyle]="true">
      <div header>
        <span class="h2 m-0">Details</span>
      </div>
      <div description *ngIf="expansionPanel.state === InsigExpansionPanelState.COLLAPSED" class="font-size-md fw-bold text-end text-nowrap w-100">
        <div *ngIf="shouldShowPaymentInformation(draftAppointment) && draftAppointment.price !== undefined" class="d-inline-block">
          <span *ngIf="discountedPrice" class="textGreen">{{ getPriceText(discountedPrice) }}</span>
          <span [ngClass]="{ 'textGreen': !discountedPrice, 'ps-1 textPink text-decoration-line-through': discountedPrice }">{{ getPriceText(draftAppointment.price) }}</span>
          <span>,&nbsp;</span>
        </div>
        <span>{{ getFormattedStartDateAndTime(draftAppointment.startTime) }}</span>
      </div>
      <div content class="px-2">
        <ng-container content *ngTemplateOutlet="appointmentDetails"></ng-container>
      </div>
    </insig-ui-expansion-panel>

    <div insigBookingScrollbarPadding class="overflow-auto mt-2">
      <insig-ui-card class="mb-2">
        <h2 class="mb-2">This appointment is for</h2>
        <div class="d-flex gap-2">
          <ng-container *ngTemplateOutlet="patientTypeButtons"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="healthCardNumberForm"></ng-container>
        <div *ngIf="isBookForSomeoneElseSelected">
          <hr>
          <ng-container *ngTemplateOutlet="familyMemberSelection"></ng-container>
        </div>
      </insig-ui-card>

      <insig-ui-card *ngIf="shouldShowPaymentInformation(draftAppointment)" class="mb-2">
        <ng-container *ngTemplateOutlet="paymentInformation"></ng-container>
      </insig-ui-card>
  
      <insig-ui-card *ngIf="isCompanyTiaHealth" class="mb-2">
        <ng-container *ngTemplateOutlet="sendNotesToFamilyDoctor"></ng-container>
      </insig-ui-card>

      <insig-ui-card class="mb-2">
        <ng-container *ngTemplateOutlet="bookingInstructions"></ng-container>
      </insig-ui-card>
  
      <insig-booking-prescription-delivery
        class="d-block"
        [province]="draftAppointment.province"
        [patientProfile]="(accountHolderProfile$ | async)!"  
      >
      </insig-booking-prescription-delivery>
    </div>

    <insig-ui-card class="sticky-bottom">
      <ng-container *ngTemplateOutlet="navigationButtons"></ng-container>
    </insig-ui-card>
  </div>
</ng-template>

<ng-template #desktopView>
  <div class="d-none d-md-flex flex-column">
    <h1>Confirm Details</h1>
    <h2 class="my-2 textSecondary font-size-md">Verify and confirm appointment details</h2>
    <div class="d-flex align-items-stretch gap-2">
      <insig-ui-card [ngClass]="{ 'max-width-40': !isBookForSomeoneElseSelected }">
        <div class="d-flex gap-2">
          <div class="d-flex flex-column" [ngClass]="isBookForSomeoneElseSelected === true ? 'w-50' : 'w-100'">
            <ng-container *ngTemplateOutlet="appointmentDetails"></ng-container>

            <div class="bold mt-2 mb-1 px-2">This appointment is for</div>
            <div class="d-flex flex-wrap gap-2 px-2 mb-2">
              <ng-container *ngTemplateOutlet="patientTypeButtons"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="healthCardNumberForm"></ng-container>
            <div *ngIf="shouldShowPaymentInformation(draftAppointment)" class="px-2">
              <ng-container *ngTemplateOutlet="paymentInformation"></ng-container>
            </div>
          </div>
  
          <ng-container *ngIf="isBookForSomeoneElseSelected">
            <hr>
            <div class="flex-grow-1">
              <ng-container *ngTemplateOutlet="familyMemberSelection"></ng-container>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="isCompanyTiaHealth">
          <ng-container *ngTemplateOutlet="sendNotesToFamilyDoctor"></ng-container>
        </ng-container>

        <ng-container *ngTemplateOutlet="bookingInstructions"></ng-container>

        <hr>
        <ng-container *ngTemplateOutlet="navigationButtons"></ng-container>
      </insig-ui-card>
      
      <insig-booking-prescription-delivery
        class="d-block"
        [province]="draftAppointment.province"
        [patientProfile]="(accountHolderProfile$ | async)!"
      >
      </insig-booking-prescription-delivery>
    </div>
  </div>
</ng-template>

<ng-template #appointmentDetails>
  <insig-booking-confirm-appointment-details
    [doctorSearchData]="doctorSearchData"
    [draftAppointment]="draftAppointment"
    [serviceType]="serviceType"
    [servicePrice]="shouldShowPaymentInformation(draftAppointment) ? servicePrice : undefined"
    [discountedPrice]="discountedPrice"
    (editButtonClicked)="navigateToChooseTimePage(draftAppointment)"
  ></insig-booking-confirm-appointment-details>
</ng-template>

<ng-template #familyMemberSelection>
  <insig-booking-family-member-selection
    *ngIf="accountHolderProfile$ | async as accountHolderProfile"
    [accountHolderProfileUid]="accountHolderProfile?.uid"
    [isHealthCardInvalid]="isHealthCardInvalid && !isDraftAppointmentUpdating"
    [selectedFamilyMemberId]="selectedFamilyMemberProfile?.familyMemberId"
    [tooManyAppointmentsError]="draftAppointment.tooManyAppointmentsError"
    [updateFamilyMembers$]="updateFamilyMembersSubject"
    (selectedFamilyMemberChange)="handleSelectedFamilyMemberChange($event)"
  ></insig-booking-family-member-selection>
</ng-template>

<ng-template #patientTypeButtons>
  <div class="d-flex flex-column flex-grow-1" *ngIf="accountHolderProfile$ | async as accountHolderProfile">
    <label
      *ngIf="draftAppointment.tooManyAppointmentsError as tooManyAppointmentsError"
      role="button"
      class="d-flex p-2 rounded align-items-center"
      matTooltip="This patient has hit the maximum number of bookings for the chosen day"
      [matTooltipDisabled]="!(tooManyAppointmentsError.tooManyAppointmentsInOneDay && !tooManyAppointmentsError.familyMemberId)"
      [ngClass]="{
        'redBorder': tooManyAppointmentsError.tooManyAppointmentsInOneDay && !tooManyAppointmentsError.familyMemberId,
        'greenBorder': !isBookForSomeoneElseSelected && !(tooManyAppointmentsError.tooManyAppointmentsInOneDay && !tooManyAppointmentsError.familyMemberId),
        'grayBorder': isBookForSomeoneElseSelected && !(tooManyAppointmentsError.tooManyAppointmentsInOneDay && !tooManyAppointmentsError.familyMemberId)
      }"
    >
      <img [src]="!isBookForSomeoneElseSelected ? 'assets/img/ico-checked-outlined.svg' : 'assets/img/ico-unchecked.svg'" class="mx-2" alt="">
      <div class="bold textSecondary">{{ accountHolderProfile?.firstName ?? 'Myself' }}</div>
      <input
        hidden
        checked
        class="ms-auto"
        type="radio"
        name="patientTypeButtons"
        (change)="handleMyselfButtonChecked(accountHolderProfile)"
      >

      <div class="mx-auto"><!-- spacer --></div>

      <div
        role="button"
        class="d-flex align-items-center"
        (click)="handleEditButtonClicked(accountHolderProfile)"
      >
        <img class="d-inline-block me-1" src="assets/img/ico-edit-pencil.svg" alt="Edit">
        <span class="d-none d-md-inline bold textBlue pe-2">Edit</span>
      </div>
    </label>
  </div>

  <div class="d-flex flex-column flex-grow-1">
    <label
    *ngIf="draftAppointment.tooManyAppointmentsError as tooManyAppointmentsError"
      role="button"
      class="d-flex p-2 rounded align-items-center"
      matTooltip="The selected family member has hit the maximum number of bookings for the chosen day"
      [matTooltipDisabled]="!(tooManyAppointmentsError?.tooManyAppointmentsInOneDay && tooManyAppointmentsError?.familyMemberId)"
      [ngClass]="{
        'redBorder': tooManyAppointmentsError?.tooManyAppointmentsInOneDay && tooManyAppointmentsError?.familyMemberId,
        'greenBorder': isBookForSomeoneElseSelected && !(tooManyAppointmentsError?.tooManyAppointmentsInOneDay && tooManyAppointmentsError?.familyMemberId),
        'grayBorder': !isBookForSomeoneElseSelected && !(tooManyAppointmentsError?.tooManyAppointmentsInOneDay && tooManyAppointmentsError?.familyMemberId)
      }"
    >
      <img [src]="isBookForSomeoneElseSelected ? 'assets/img/ico-checked-outlined.svg' : 'assets/img/ico-unchecked.svg'" class="mx-2" alt="">
      <div class="bold textSecondary">Someone else</div>
      <input
        hidden
        class="ms-auto"
        type="radio"
        name="patientTypeButtons"
        (change)="handleSomeoneElseButtonChecked()"
      >
    </label>
  </div>
</ng-template>

<ng-template #sendNotesToFamilyDoctor>
  <ng-container *ngIf="accountHolderProfile$ | async as accountHolderProfile">
    <ng-container *ngIf="isCompanyTiaHealth && isAppointmentEligibleForMrpFax">
      <div>
        <div class="px-0 px-md-2">
          <insig-ui-checkbox-button
            label="I would like to request a copy of these medical notes to be sent to my family doctor. I acknowledge that the physician may be unable to fulfill this request"
            [isChecked]="isBookForSomeoneElseSelected && selectedFamilyMemberProfile ? !!selectedFamilyMemberProfile.prefersSendNoteToFamilyDoctor : !!(accountHolderProfile$ | async)?.prefersSendNoteToFamilyDoctor"
            (change)="handleSendNotesToFamilyDoctorCheckboxClicked($event)"
          ></insig-ui-checkbox-button>
        </div>
      </div>
      
      <insig-booking-family-doctor-form
        *ngIf="(isBookForSomeoneElseSelected && selectedFamilyMemberProfile) || (!isBookForSomeoneElseSelected && (accountHolderProfile$ | async))"
        [patientProfile]="isBookForSomeoneElseSelected ? selectedFamilyMemberProfile : (accountHolderProfile$ | async)!"
        [faxNotesToFamilyDoctor]="isBookForSomeoneElseSelected && selectedFamilyMemberProfile ? !!selectedFamilyMemberProfile.prefersSendNoteToFamilyDoctor : !!(accountHolderProfile$ | async)?.prefersSendNoteToFamilyDoctor"
        (familyDoctorSaved)="handleFamilyDoctorSaved()"
      ></insig-booking-family-doctor-form>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #bookingInstructions>
  <div>
    <div class="px-0 px-md-2">
      <h2 class="mt-2 mb-1">{{ getBookingInstructionsHeader(doctorSearchData) }}</h2>
      {{ getBookingInstructions(doctorSearchData) }}
      <br/><br/>
      {{ ConfirmBookingComponent.DEFAULT_BOOKING_INSTRUCTIONS_FOOTER }}
    </div>

    <div class="pt-2 px-md-2">
      <insig-ui-checkbox-button
        class="rounded"
        label="I have read the above instructions and confirm that my information is correct"
        isRequired="true"
        [(ngModel)]="isBookingInstructionsCheckboxChecked"
      ></insig-ui-checkbox-button>
    </div>
  </div>
</ng-template>

<ng-template #healthCardNumberForm>
  <insig-booking-health-card-number-form
    *ngIf="isHealthCardInvalid && !isBookForSomeoneElseSelected && !isDraftAppointmentUpdating"
    class="my-2 px-2"
    [patientProfile]="(accountHolderProfile$ | async)!"
    [isAccountHolder]="true"
    (healthCardSaved)="handleHealthCardSaved()"
  ></insig-booking-health-card-number-form>
</ng-template>

<ng-template #paymentInformation>
  <div *ngIf="paymentInformationState === PaymentInformationState.NO_CARD" class="mb-1 mt-2">
    <h2>Add a Payment Method</h2>
  </div>
  <div *ngIf="paymentInformationState === PaymentInformationState.USE_EXISTING_CARD || paymentInformationState === PaymentInformationState.UPDATE_CARD" class="mt-2">
    <h2>Payment Details</h2>
    <p role="doc-subtitle" class="font-size-md">Select payment method</p>
  </div>

  <div class="d-flex align-items-center justify-content-between">
    <div *ngIf="lastFourDigitsOfExistingPaymentCard !== undefined" class="d-flex align-items-center">
      <img [src]="paymentInformationState === PaymentInformationState.USE_EXISTING_CARD ? 'assets/img/ico-checked-outlined.svg' : 'assets/img/ico-unchecked.svg'" class="mx-2" alt="">
      <div>Use credit card ending in {{ lastFourDigitsOfExistingPaymentCard }}</div>
    </div>
    <insig-ui-button *ngIf="paymentInformationState === PaymentInformationState.USE_EXISTING_CARD"
      iconSrc="assets/img/ico-edit-pencil.svg"
      stylePreset="flat"
      (click)="handlePaymentInformationChangeButtonClicked()"
    >
      Change
    </insig-ui-button>

    <insig-ui-button *ngIf="paymentInformationState === PaymentInformationState.UPDATE_CARD"
      iconSrc="assets/img/ico-edit-pencil.svg"
      stylePreset="flat"
      (click)="handlePaymentInformationCancelButtonClicked()"
    >
      Cancel
    </insig-ui-button>
  </div>

  <div *ngIf="paymentInformationState === PaymentInformationState.NO_CARD || paymentInformationState === PaymentInformationState.UPDATE_CARD" class="px-2 mb-2">
    <insig-booking-stripe-payment-source-form
      class="w-100"
      [accountHolderProfile]="(accountHolderProfile$ | async)!"
      (paymentSourceSaved)="handlePaymentSourceSaved($event)"
    ></insig-booking-stripe-payment-source-form>
  </div>

  <insig-booking-discount-code-form
    (discountedPriceChange)="setDiscountedPrice($event)"
  ></insig-booking-discount-code-form>
</ng-template>

<ng-template #navigationButtons>
  <div class="d-flex align-items-center justify-content-between">
    <insig-ui-button
      stylePreset="tertiary"
      (click)="handleBackButtonClicked()"
    >
      Back
    </insig-ui-button>
    <ng-container *ngIf="showSpinner; else bookNowButton">
      <mat-progress-spinner
        class="d-flex align-items-end"
        mode="indeterminate"
        [diameter]="25"
      ></mat-progress-spinner>
    </ng-container>
    <ng-template #bookNowButton>
      <insig-ui-button
        iconSrc="assets/img/ico-calendar.svg"
        (click)="handleBookNowButtonClicked()"
        [disabled]="isBookNowButtonDisabled(paymentInformationState, isBookForSomeoneElseSelected, selectedFamilyMemberProfile, isBookingInstructionsCheckboxChecked, isHealthCardInvalid, draftAppointment)"
      >
        Book Now
      </insig-ui-button>
    </ng-template>
  </div>
</ng-template>
