<div>
  <h1>Create your account</h1>
  <p role="doc-subtitle" class="mb-3">Enter your account details to continue</p>
  <form class="d-flex flex-column flex-lg-row justify-content-center justify-content-md-start gap-2 max-lg-height-600px" [formGroup]="patientRegistrationForm">
    <insig-ui-card class="d-flex flex-column flex-lg-row justify-content-between w-100 w-lg-75 w-xl-66 px-4">
      <ng-content *ngTemplateOutlet="patientDetailsForm"></ng-content>
      <hr class="d-none d-lg-block my-0 mx-4">
      <ng-content *ngTemplateOutlet="pharmacyForm"></ng-content>
    </insig-ui-card>
    <ng-content *ngTemplateOutlet="pharmacyListDesktop"></ng-content>

    <ng-template #patientDetailsForm>
      <ng-container [formGroupName]="SIGN_UP_DETAILS_FORM_NAME">
        <div class="d-flex flex-column gap-2">
          <!-- #region address-->
          <div class="d-flex flex-column flex-md-row">
            <div class="d-flex flex-column me-0 w-100 pb-2">
              <insig-ui-text-input
                #addressInput
                label="Address"
                [formControlName]="ADDRESS_FORM_CONTROL_NAME"
                name="street-address"
                isRequired="true"
              >
              </insig-ui-text-input>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[ADDRESS_FORM_CONTROL_NAME]"
                errorText="Address is required."
                errorType="required"
              >
              </insig-ui-form-error>
            </div>
          </div>
          <!-- #endregion address-->

          <!-- #region province and country-->
          <div class="d-flex flex-row">
            <div class="d-flex flex-column me-2 w-50">
              <insig-ui-select label="Province" isRequired="true" [formControlName]="PROVINCE_FORM_CONTROL_NAME">
                <option
                  *ngFor="let healthCardProvince of healthCardProvinces"
                  class="notranslate bold"
                  [value]="healthCardProvince"
                >
                  {{ healthCardProvince }}
                </option>
              </insig-ui-select>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[PROVINCE_FORM_CONTROL_NAME]"
                errorText="Province is required."
                errorType="required"
              >
              </insig-ui-form-error>
            </div>
            <div class="d-flex flex-column ms-2 w-50">
              <insig-ui-select label="Country" isRequired="true" [formControlName]="COUNTRY_FORM_CONTROL_NAME">
                <option value="Canada" class="bold">Canada</option>
                <option value="United States of America" class="bold">United States of America</option>
              </insig-ui-select>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[COUNTRY_FORM_CONTROL_NAME]"
                errorText="Country is required."
                errorType="required"
              >
              </insig-ui-form-error>
            </div>
          </div>
          <!-- #endregion province and country-->

          <!-- #region city and postal code-->
          <div class="d-flex flex-row">
            <div class="d-flex flex-column me-2 w-50">
              <insig-ui-text-input
                label="City"
                [formControlName]="CITY_FORM_CONTROL_NAME"
                name="city"
                isRequired="true"
              >
              </insig-ui-text-input>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[CITY_FORM_CONTROL_NAME]"
                errorText="City is required."
                errorType="required"
              >
              </insig-ui-form-error>
            </div>
            <div class="d-flex flex-column ms-2 w-50">
              <insig-ui-text-input
                label="Zip/Postal Code"
                [formControlName]="POSTAL_CODE_FORM_CONTROL_NAME"
                name="postal-code"
                isRequired="true"
              >
              </insig-ui-text-input>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[POSTAL_CODE_FORM_CONTROL_NAME]"
                errorText="Postal Code is required."
                errorType="required"
              >
              </insig-ui-form-error>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[POSTAL_CODE_FORM_CONTROL_NAME]"
                errorText="Postal Code must be valid."
                errorType="postalCodeNotValid"
              >
              </insig-ui-form-error>
            </div>
          </div>
          <!-- #endregion city and postal code-->

          <!-- #region dob and health card-->
          <div class="d-flex flex-row">
            <div class="d-flex flex-column me-2 w-50">
              <insig-ui-date-picker label="Date of Birth" isDisabled="false" [formControlName]="DATE_OF_BIRTH_FORM_CONTROL_NAME" [maxDate]="maximumBirthdate" isRequired="true">
              </insig-ui-date-picker>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[DATE_OF_BIRTH_FORM_CONTROL_NAME]"
                errorText="Date of Birth is required."
                errorType="required"
              >
              </insig-ui-form-error>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[DATE_OF_BIRTH_FORM_CONTROL_NAME]"
                errorText="Date of Birth must be a valid date."
                errorType="birthdateIsNotValid"
              >
              </insig-ui-form-error>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[DATE_OF_BIRTH_FORM_CONTROL_NAME]"
                errorText="Date of Birth must be in the format YYYY-MM-DD."
                errorType="birthdateNotInCorrectFormat"
              >
              </insig-ui-form-error>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[DATE_OF_BIRTH_FORM_CONTROL_NAME]"
                errorText="You must be older to register."
                errorType="dateIsAfterMaximumDate"
              >
              </insig-ui-form-error>
            </div>
            <div class="d-flex flex-column ms-2 w-50">
              <insig-ui-text-input
                label="Health Card"
                [formControlName]="HEALTH_CARD_NUMBER_FORM_CONTROL_NAME"
                isRequired="false"
              >
              </insig-ui-text-input>
            </div>
          </div>
          <!-- #endregion dob and health card-->

          <!-- #region family doctor and gender -->
          <div class="d-flex flex-row">
            <div class="d-flex flex-column me-2 w-50">
              <insig-health-family-doctor-search
                [provinceControl]="signUpDetailsForm.controls[PROVINCE_FORM_CONTROL_NAME]"
                [familyDoctorFullNameControl]="signUpDetailsForm.controls[FAMILY_DOCTOR_FULL_NAME_FORM_CONTROL_NAME]"
                (familyDoctorSelected)="handleFamilyDoctorClicked($event)"
              ></insig-health-family-doctor-search>
            </div>
            <div class="d-flex flex-column ms-2 w-50">
              <insig-ui-text-input
                insig-phone-mask
                label="Family Doctor Fax Number"
                [formControlName]="FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME"
              ></insig-ui-text-input>
              <insig-ui-form-error
                [formControlForErrorMessage]="signUpDetailsForm.controls[FAMILY_DOCTOR_FAX_NUMBER_FORM_CONTROL_NAME]"
                errorText="Not a valid fax number."
                errorType="phoneNumberNotValid"
              >
              </insig-ui-form-error>
            </div>
          </div>

          <div class="d-flex flex-column flex-even w-50">
            <insig-ui-select label="Designated Gender at Birth" isRequired="true" [formControlName]="GENDER_FORM_CONTROL_NAME">
              <option value="">Select</option>
              <option
                *ngFor="let gender of genders"
                class="bold"
                [value]="gender"
              >
                {{ gender }}
              </option>
            </insig-ui-select>
            <insig-ui-form-error
              [formControlForErrorMessage]="signUpDetailsForm.controls[GENDER_FORM_CONTROL_NAME]"
              errorText="Gender is required."
              errorType="required"
            ></insig-ui-form-error>
          </div>
          <!-- #endregion family doctor and gender -->
        </div>
      </ng-container>
    </ng-template>

    <ng-template #pharmacyForm>
      <div class="d-flex flex-column w-100 w-lg-50 mt-4 mt-lg-0">
        <insig-booking-pharmacy-map
          [pharmacies]="pharmacies"
          [selectedPharmacy]="selectedPharmacy"
          (pharmacyMarkerClicked)="handlePharmacyMarkerClicked($event)"
        ></insig-booking-pharmacy-map>
  
        <ng-container *ngIf="selectedPharmacy !== undefined">
          <img [hidden]="selectedPharmacy.logo === undefined" [src]="selectedPharmacy.logo" class="mx-auto vh-10" alt="Logo of selected pharmacy" />
        </ng-container>
  
        <ng-container [formGroupName]="PHARMACY_DETAILS_FORM_NAME">
          <div class="d-flex flex-column mb-2">
            <div class="w-100">
              <insig-ui-text-input
                class="py-2"
                label="Pharmacy Name"
                [formControlName]="PHARMACY_NAME_FORM_CONTROL_NAME"
                isRequired="true"
              >
              </insig-ui-text-input>
              <insig-ui-form-error
                [formControlForErrorMessage]="pharmacyDetailsForm.controls[PHARMACY_NAME_FORM_CONTROL_NAME]"
                errorText="Pharmacy Name is required."
                errorType="required"
              >
              </insig-ui-form-error>
            </div>
            <div class="w-100">
              <insig-ui-text-input
                insig-phone-mask
                class="py-2"
                label="Pharmacy Fax Number"
                [formControlName]="PHARMACY_FAX_FORM_CONTROL_NAME"
                isRequired="true"
              >
              </insig-ui-text-input>
              <insig-ui-form-error
                [formControlForErrorMessage]="pharmacyDetailsForm.controls[PHARMACY_FAX_FORM_CONTROL_NAME]"
                errorText="Pharmacy Fax is required."
                errorType="required"
              >
              </insig-ui-form-error>
              <insig-ui-form-error
                [formControlForErrorMessage]="pharmacyDetailsForm.controls[PHARMACY_FAX_FORM_CONTROL_NAME]"
                errorText="Pharmacy Fax is not a valid phone number."
                errorType="phoneNumberNotValid"
              >
              </insig-ui-form-error>
            </div>
          </div>
        </ng-container>

        <ng-content *ngTemplateOutlet="pharmacyListMobile"></ng-content>

        <div class="d-flex justify-content-between pt-3">
          <insig-ui-button [hidden]="selectedPharmacy === undefined" stylePreset="tertiary" (click)="handleChangePharmacyButtonClicked()">
            Change Pharmacy
          </insig-ui-button>
          <ng-container *ngIf="showSpinner; else registerButton">
            <mat-progress-spinner
              class="d-flex align-items-end"
              mode="indeterminate"
              [diameter]="25"
            ></mat-progress-spinner>
          </ng-container>
          <ng-template #registerButton>
            <insig-ui-button [disabled]="patientRegistrationForm.invalid" class="ms-auto ps-2" iconSrc="assets/img/ico-signup-wh.svg" (click)="handleRegisterButtonClick()">
              Finish Sign Up
            </insig-ui-button>
          </ng-template>
        </div>
      </div>
    </ng-template>

    <ng-template #pharmacyListDesktop>
      <insig-ui-card *ngIf="pharmacies.length > 0" class="d-none d-lg-flex flex-column w-25 w-xl-33">
        <h2 class="my-2">Pharmacies near you</h2>
        <hr>
        <div class="overflow-auto">
          <ng-content *ngTemplateOutlet="pharmacyList"></ng-content>
        </div>
      </insig-ui-card>
    </ng-template>

    <ng-template #pharmacyListMobile>
      <div *ngIf="pharmacies.length > 0" class="d-flex d-lg-none">

        <insig-ui-expansion-panel class="w-100">
          <div header class="bold">
            <ng-container *ngIf="selectedPharmacy !== undefined; else choosePharmacyText">
              <span>Selected pharmacy: {{ selectedPharmacy.name }}</span>
            </ng-container>
            <ng-template #choosePharmacyText>
              <span>Choose a pharmacy</span>
            </ng-template>
          </div>
          <div content>
            <ng-content *ngTemplateOutlet="pharmacyList"></ng-content>
          </div>
        </insig-ui-expansion-panel>
      </div>
    </ng-template>

    <ng-template #pharmacyList>
      <div class="d-flex flex-column px-2">
        <ng-container *ngFor="let pharmacy of pharmacies">
          <label 
            class="d-flex rounded w-100 p-2 mb-3"
            [ngClass]="{ 'selected': pharmacy.id === selectedPharmacy?.id }"
          >
            <div class="d-flex flex-column">
              <div class="bold text-break">{{ pharmacy.name }}</div>
              <div class="textSecondary">{{ pharmacy.address }}</div>
            </div>
            <input
              class="ms-auto"
              type="radio"
              name="pharmacies"
              [value]="pharmacy.id"
              (click)="handlePharmacyRadioClicked(pharmacy)"
            >
            <img [src]="pharmacy.id === selectedPharmacy?.id ? 'assets/img/ico-checked-outlined.svg' : 'assets/img/ico-unchecked.svg'" alt="">
          </label>
        </ng-container>
      </div>
    </ng-template>
  </form>
</div>
