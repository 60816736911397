import { ActivatedRouteSnapshot, CanDeactivate, createUrlTreeFromSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { NavigationWarningDialogComponent } from '../../app/navigation-warning-dialog/navigation-warning-dialog.component';

import { AppointmentReservationService, DraftAppointmentSlot } from '../../services/appointment-reservation/appointment-reservation.service';
import { firstValueFrom } from 'rxjs';
import { BookingStep, BookingStepService } from '../../services/booking-step/booking-step.service';
import { CompanyBookingComponent } from '../../app/company-booking/company-booking.component';
import { BillingTypeService } from '../../services/billing-type/billing-type.service';
import { ConfirmBookingComponent } from '../../app/confirm-booking/confirm-booking.component';
import { YourDetailsComponent } from '../../app/your-details/your-details.component';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { BillingRegionService } from '../../services/billing-region/billing-region.service';

@Injectable({
  providedIn: 'root',
})
export class TimeslotReservationGuard implements CanDeactivate<YourDetailsComponent | ConfirmBookingComponent> {

  constructor(
    private billingTypeService: BillingTypeService,
    private bookingStepService: BookingStepService,
    private billingRegionService: BillingRegionService,
    private appointmentReservationService: AppointmentReservationService,
    private gcpIpAuthService: GcpIpAuthService,
    private dialog: MatDialog,
  ) {}

  async canDeactivate(_component: YourDetailsComponent | ConfirmBookingComponent, _route: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const reservedAppointmentSlot = await firstValueFrom(this.appointmentReservationService.getCurrentReservedAppointmentSlot());
    const nextStep = this.getBookingStepFromSnapshot(nextState);
    const currentStep = this.getBookingStepFromSnapshot(currentState);
    const isLoggedIn = await firstValueFrom(this.gcpIpAuthService.isLoggedIn());

    const isNotLoggedInOnConfirmationStep = currentStep === BookingStep.CONFIRM_BOOKING && !isLoggedIn;
    if (reservedAppointmentSlot === undefined || nextStep === BookingStep.CONFIRM_BOOKING || isNotLoggedInOnConfirmationStep) {
      if (reservedAppointmentSlot !== undefined && isNotLoggedInOnConfirmationStep) {
        await this.reopenAppointmentSlot(reservedAppointmentSlot);
      }
      return true;
    }

    const dialogRef = this.getNavigationWarningDialog();

    const canNavigate = await firstValueFrom(dialogRef.afterClosed());
    if (canNavigate) {
      await this.reopenAppointmentSlot(reservedAppointmentSlot);
      if (nextState.root.queryParamMap.get('redirectedFromLogout') === 'true') {
        await this.gcpIpAuthService.signOut();
        return createUrlTreeFromSnapshot(nextState.root, []);
      }
    }

    if (nextStep !== BookingStep.LOGIN) {
      return canNavigate ?? false;
    } else {
      const companyBookingRoute = this.bookingStepService.getActivatedRouteOfComponentType(currentState.root, CompanyBookingComponent);

      const region = this.billingRegionService.getBillingRegion(reservedAppointmentSlot.province, this.billingTypeService.parseBillingType(reservedAppointmentSlot.billingType));

      return this.bookingStepService.getUrlTreeForChooseTimeStep({
        relativeTo: companyBookingRoute,
        pathParams: {
          region: region,
          doctorId: reservedAppointmentSlot.doctorId,
          serviceId: reservedAppointmentSlot.serviceId,
        },
      });
    }
  }

  getBookingStepFromSnapshot(snapshot: RouterStateSnapshot): BookingStep | undefined {
    if (snapshot.url === undefined) {
      return undefined;
    }

    return this.bookingStepService.getStepFromUrl(snapshot.url);
  }

  getNavigationWarningDialog(): MatDialogRef<NavigationWarningDialogComponent> {
    const existingNavigationWarningDialog = this.dialog.getDialogById(NavigationWarningDialogComponent.DEFAULT_DIALOG_ID);
    if (existingNavigationWarningDialog) {
      return existingNavigationWarningDialog;
    } else {
      return this.dialog.open(NavigationWarningDialogComponent, NavigationWarningDialogComponent.DEFAULT_DIALOG_CONFIG);
    }
  }

  async reopenAppointmentSlot(reservedAppointmentSlot: DraftAppointmentSlot): Promise<void> {
    await this.appointmentReservationService.deleteAppointmentDraft(reservedAppointmentSlot.appointmentId);
  }
}
